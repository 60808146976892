/* You can add global styles to this file, and also import other style files */

@import 'global-styles/primeng-styles.css';
@import 'global-styles/compact-theme.css';
@import 'flags.css';

:root {
  --success-background: rgb(227, 252, 239);
  --error-background: rgb(255, 235, 230);
  --info-background: #e9e9ff;
  --warn-background: #fff2e2;

  --success-text: rgb(0, 102, 68);
  --error-text: rgb(191, 38, 0);
  --warn-text: #cc8925;
}

* {
  font-family: 'Roboto', sans-serif;
}

.securityLink {
  color: blue;
  cursor: pointer;
  border-bottom: 1px solid blue;
}

.p-accordion-header-text {
  font-weight: bold;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.75;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header.p-disabled .p-panelmenu-header-action > * {
  opacity: 0.75;
}

.p-calendar-w-btn.p-calendar-disabled {
  opacity: 0.75;
}

.p-calendar .p-inputtext {
  width: auto;
}
/* Crude band-aid to fix user profile tooltip's position */
.profile-button-tooltip {
  transform: translate(57px, 33px);
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0% !important;
}

/* Apply this to panelStyleClass of p-autoComplete or p-dropdown components to prevent it from
 going beyond the right edge of the page */
.app-sticky-panel {
  position: sticky;
  max-width: 90vw;
  right: 0;
}

/* common style for tables */
table.p-datatable-table > thead > tr > th {
  background-color: lightgrey !important;
}
table.p-datatable-table > tbody > tr > td {
  font-family: sans-serif !important;
  font-size: smaller;
}

/* .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  transition: background-color 0.2s;
  border-radius: 0;
  background: lightsteelblue;
} */
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  transition: background-color .2s;
  border-radius: 0;
  background: lightsteelblue;
}
.p-tabview .p-tabview-nav-content {
  scroll-padding-inline: 2.75rem;
  background: lightsteelblue;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  font-style: italic;
  font-weight: 300;
  font-size: small;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #fff;
  border-color: #0000001f;
  color: #3f51b5;
}

.p-float-label .p-inputwrapper {
  height: 100%;
}

.p-overlay {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  left: 0;
}
.p-overlay.p-component {
  width: auto;
  position: absolute;
}
.p-autocomplete-item {
  overflow-wrap: break-word;
  white-space: normal;
}
.p-dropdown-item {
  overflow-wrap: break-word;
  white-space: normal;
}

/* //https://stackoverflow.com/a/52887166/11192166 */
.p-toast-detail {
  white-space: pre-line;
}

/* .minDrop {
  position: absolute;
  left: 75vw;
} */
