/* NOTE - This file must be included globally. Otherwise, Angular's view encapsulation will nerf this. */

/* compact theme - use this on any component to make all descendent Prime inputs smaller */

.use-compact-theme .p-inputtext {
  padding: 0.6rem 0.5rem;
}
.use-compact-theme .p-datepicker table td {
  padding: 0;
}
.use-compact-theme .p-datepicker table {
  font-size: 0.7rem;
}

.use-compact-theme p-checkbox {
  transform: scale(90%) translateX(-5%);
}

.use-compact-theme app-bordered-field-group .group {
  padding-block: 10px;
}

.use-compact-theme h3 {
  margin-block: 0;
}

.use-compact-theme .p-button {
  padding: 0.5rem;
}
.use-compact-theme .action-item-container .p-button-icon-only {
  height: 1.75rem;
  width: 1.75rem;
}

.use-compact-theme .navbar .p-button {
  height: 1.75rem;
  width: 1.75rem;
}
.use-compact-theme .navbar .p-menubar {
  height: 40px;
}
.use-compact-theme .header-content {
  padding-top: 40px;
}

.use-compact-theme .add-notice {
  padding-top: 80px;
}

.use-compact-theme .estm-breadcrumb .p-breadcrumb {
  padding: 0px;
}
.use-compact-theme .p-component {
  font-size: 0.75rem;
}
.use-compact-theme .p-inputtext {
  font-size: 0.75rem;
}

.use-compact-theme .p-panelmenu-header-action {
  padding: 1rem;
}
